import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import { graphql } from 'gatsby'
import Image from 'gatsby-plugin-sanity-image'
import { sanityConfig, serializers } from '../utils/helpers'
import Seo from '../components/seo'
import Layout from '../components/layout'

const ShopGoblinMuseum = ({ data }) => {
  const page = data.sanityBasicPage
  const image = page?.mainImage?.image
  return (
    <Layout>
      <Seo title="Shop the Goblin Museum" />
      {image && (
        <div className="sidebar-image image">
          <Image {...image} alt={page.mainImage.alt} />
        </div>
      )}
      <article>
        <h1>{page.title}</h1>
        {page?._rawBody && (
          <BlockContent
            blocks={page._rawBody}
            serializers={serializers}
            {...sanityConfig}
          />
        )}
      </article>
    </Layout>
  )
}

export default ShopGoblinMuseum


export const query = graphql`
  query ShopPageQuery {
    sanityBasicPage(_id: { eq: "2e1b1ec3-9ba9-4799-9730-797fbeca4fef" }) {
      title
      _rawBody
      mainImage {
        alt
        image {
          ...ImageWithPreview
        }
      }
    }
  }
`
